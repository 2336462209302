@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0, 0%, 8%;
    --background-active: 0, 0%, 6%;
    --background-secondary: 0, 0%, 10%;

    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 240 5% 65%;
 
    --accent: 60, 75%, 75%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 357, 65%, 58%;
    --destructive-foreground: 210 40% 98%;

    --border: 0, 0%, 16%;
    --border-foreground: 0, 0%, 98%;

    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --text-primary: 0, 0%, 94%;
    --text-secondary: 0, 0%, 67%;
    --text-tertiary:0, 0%, 40%;
    --text-disabled: 0, 0%, 31%;

    --status-warning: 44, 63%, 60%;
    --status-success: 151, 98%, 43%;
    --status-negative: 151, 98%, 43%;
 
    --radius: 0.5rem;

    --complementary: 222, 68%, 39%;
    --complementary-light: 151, 98%, 43%;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 240 5% 65%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 240 7% 16%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* 
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */
